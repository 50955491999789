import aboutWebp from '../assets/aboutWebp.webp'
import aboutPng from '../assets/aboutPng.png'

export const aboutUsParallax = {
  banner: true,
  color: '#43b2fca6',
  image: aboutWebp,
  pngImage: aboutPng,
  title: `About Us`,
  h1: true,
  paragraphTitle: `FreeMotorLegal was set up to save motorists money.`,
  paragraphContent: [
    {
      text: `Lee Jones has been in this industry for over 20 years, working for both insurers and law firms dealing with claims for people who had motor legal protection. The reality was that even though the clients had legal cover, the actual insurers of the legal protection policies would rarely pay any legal fees out to the solicitors doing the work. It was just a money maker for insurers and brokers earning big commission fees from the law firms that were appointed under the policy. This is why these policies always dictate that you must use the lawyer appointed by them.` 
    },
    {
      text: `He felt there had to be a better way of operating. So he did something about it.`
    },
    {
      text: `Free Motor Legal was established in 2012 to provide motorists in England, Scotland & Wales with a way of making sure they had all the necessary assistance after a non-fault collision, but without the need to charge them for an annual policy. And it’s working! Here’s what some of our members have said to us:`
    }
  ],
}

export const reviewList = {
  title: '',
  description: '',
  cardsPerLine: 4,
  reviewList: [
    {
      author: "Mr I MacKintosh",
      review: `“Prompt service, well handled, kept informed, stress free, very satisfied, thank you.”`
    },
    {
      author: "Mr A. Coleman",
      review: `“For a free service the attention to detail was as if I was paying £100’s of pounds. I would recommend you to all my friends.”`
    },
    {
      author: "Mr T. Von Trilsbach",
      review: `“Thorough, personal and attentive service at all times. Thank you for all your help and assistance with my claim.”`
    },
    {
      author: "Mr J. Lilley",
      review: `“Very good help at a very bad time. Thank you.”`
    },
    {
      author: "Mrs P. Ward",
      review: `“You rescued us after the accident and when our insurers made an upsetting time for us even worse by their incompetence, you came to our rescue again. I cannot thank you enough. I would urge anybody thinking of joining FML, just do it.”`
    },
    {
      author: "Mr L. Findell",
      review: `“Really great service actually. The advice given was spot on. I was initially worried the appointed solicitors from my insurance company were going to charge me for their time. But advice from the man on the phone at FreeMotorLegal advised to push on with your service and our newly appointed solicitors. Turned out to be great advice. The new solicitors were excellent. Good communication throughout, pushed the offending insurance company and solicitors for answers and got the incident resolved in a successful manner for my wife & I, very pleased. Thank you”`
    },
    {
      author: "Mr A. Khobnya",
      review: `“I am very grateful to Lee Jones from Free Motor Legal who took personal interest in my case and greatly assisted me in finding a company that dealt with my claim and damages to a full satisfaction. Thanks to Drive Car hire and Roy Powell for their professional and courteous service. Special thanks to Michelle Jones from Corries Solicitors who dealt with my claim.”`
    },
    {
      author: "Mr P. Mason",
      review: `“I had paid for legal cover, after 1 year of no help I signed up to FreeMotor Legal, where they helped and sorted the probloem out in a matter of weeks. Without FreeMotorLegal I would not have been able to claim on the other party’s insurance. I personally recommend from experience, no fees, quality help & advice right till the end.”`
    },
    {
      author: "Mrs L. Thomas",
      review: `“From the first call to FreeMotorLegal they dealt with my claim. They guided me through the process taking away all the stress and worry associated with the accident. Both FreeMotorLegal and the solicitors dealt with my claim in a professional way without any reminders from me. Thanks and well done.”`
    },
    {
      author: "Mr S. Cheshire",
      review: `“I was very pleased with the service provided by FreeMotorLegal and the Solicitor. Both were courteous and gave me a full explanation of what the procedures were. I received very good communication from the solicitor especially, who gave excellent customer service.”`
    },
    {
      author: "Mr J. Beeden",
      review: `“A supermarket trolley was pushed into my car by a Tesco employee and caused £500+ damage. You were able to get them to reimburse me. Thank you.”`
    },
    {
      author: "Mrs S. Knobnya",
      review: `“I am thankful to FreeMotorLegal for the advice they gave and for taking care of our case. Special thanks to Mr Jones for diligent communication and resolving our case.”`
    },
    {
      author: "Mrs L. Dickinson",
      review: `“Excellent service, fast, reliable. Highly recommended.”`
    },
    {
      author: "Ms E. Myers",
      review: `“The process wnet smoothly, excellent service from the solicitors.”`
    },
    {
      author: "Mrs M. Martin",
      review: `“Very pleased with the service, no hassles, everything went smoothly.”`
    },
    {
      author: "Mr D. Billing",
      review: `“Lee was very thorough and helpful answering my questions. Thanks!”`
    },
  ]
}

export const lastBanner = {
  small: false,
  displayBtn: true,
  title: "What are you waiting for?",
  subtitle: 'Sign up. It costs you nothing and will save you money and headaches should you be in a non-fault accident.',
  label: "SIGN UP NOW",
  link: "true",
  url: "/sign-up-to-fml/",
  bgColor: "#0bbcbf",
  padding: true,
}