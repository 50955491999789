import React, { useState, useEffect } from "react"
import "./review-cards.scss"
import ReviewBox from "../review-box/review-box"
import third from "../../assets/third.png";
import { TiTick } from "react-icons/ti";



const ReviewCards = ({ props }) => {

    const [isReadMoreShown, setReadMoreShown] = useState(false)
    const toggleBtn = () => {
        setReadMoreShown(prevState => !prevState)
    }

    return (
        <div className="reviews-container">
            <div className="reviews-content">

                {props.title ? <h2 className="">{props.title}</h2> : ""}
                {props.description ? <p className="">{props.description}</p> : ""}

                <div className="review-cards-container">
                    <div className={`uk-child-width-1-${props.cardsPerLine}@l uk-child-width-1-2@m uk-child-width-1-2@s  uk-grid-match uk-grid-small uk-grid`} uk-grid="true">
                        {
                            props.reviewList ? props.reviewList.map((item, index) => {
                                return (
                                    <div>
                                        {/* <TiTick/> */}
                                        <ReviewBox props={item} key={index}/>
                                    </div>

                                )
                            })
                                : ""
                        }
                    </div>
                </div>
            </div>
        </div>
    )



}

export default ReviewCards