import React from "react"
import 'uikit/dist/css/uikit.css'
import Layout from "../layouts"
import { aboutUsParallax, reviewList, lastBanner } from "../utils/aboutInformation";
import ParallaxComponent from "../components/parallax-component/parallax-component"
import Banner from "../components/banner/banner";
import ReviewCards from "../components/review-cards/review-cards";




// markup
const About = () => {


  return (
    <Layout SEOTitle={"About | Free Motor Legal"} SEODescription={""} footerDisclaimer={""}>

      <ParallaxComponent props={aboutUsParallax} />

      <ReviewCards props={reviewList} />

      <Banner props={lastBanner} />

    </Layout>
  )

}

export default About